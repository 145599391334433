<template>
  <div class="row py-5">
    <div class="col-12">
      <div class="wrapper-title-tournament">
        <h6 class="title">Ranking</h6>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="card">
        <strong class="card-header text-center">{{$t('rankingPlayers')}} - {{`${console.toUpperCase()}`}}</strong>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" v-for="user in users" :key="user.nick">
            <div class="wrapper-ranking-user">
              <div class="details">
                <img :src="user.avatar" :alt="user.nick">
                <div>
                  <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: user.slug } }">{{user.nick}}</router-link><br>
                  <span class="text-muted">{{user.ptos}} pts</span>
                </div>
              </div>
              <div class="country">
                <img :src="user.country_flag" :alt="user.country_name" class="mb-1"><br>
                <small class="text-muted">{{user.country_name}}</small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="card">
        <strong class="card-header text-center">{{$t('rankingTeams')}} - {{`${console.toUpperCase()}`}}</strong>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" v-for="team in teams" :key="team.id">
            <div class="wrapper-ranking-user">
              <div class="details">
                <img :src="team.image" :alt="team.name">
                <div>
                  <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: team.slug } }">{{team.name}}</router-link><br>
                </div>
              </div>
              <div class="country">
                <span class="text-muted">{{team.pts}} pts</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    users: {
      required: true,
      type: Array
    },
    teams: {
      required: true,
      type: Array
    },
    console: {
      required: true,
      type: String,
      default: 'psn'
    },
    lang: {
      required: true,
      type: String,
      default: 'en'
    }
  }
}
</script>
